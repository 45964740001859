<template>

  <ol>
    <li v-for="sample in sample.samples" :key="sample.id">
      <SampleCard :sample="sample" />
    </li>
  </ol>
 <p>总数:{{ sample.sampleTotal }}</p>
  <router-link
    class="link"
    v-if="page != 1"
    :to="{ name: 'samplelist', params: { page: page - 1 } }"
    rel="prev"
  >
    上一页</router-link
  >
  <router-link
    class="link"
    v-if="hasNextPage"
    :to="{ name: 'samplelist', params: { page: page + 1 } }"
    rel="next"
  >
    下一页</router-link
  >
  <div>
    <span>当前页码{{ page }}</span>
    <input type="text" v-model.number="page1" />
    <button @click="jumpPage">跳到指定页码</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import { ref, watchEffect } from "vue";
import SampleCard from "@/components/SampleCard";

function getPageEvents(routeTo, next) {
  const currentPage = parseInt(routeTo.params.page) || 1;
  store.dispatch("sample/fetchSamples", { page: currentPage }).then(() => {
    routeTo.params.page = currentPage;
    next();
  });
}
function getSampleCount() {
  store.dispatch("sample/fetchSampleCount");
}

export default {
  name: "SampleList",
  props: {
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  components: {
    SampleCard,
  },
  setup(props) {
    const page1 = ref(1);
    // watch(()=>props.page,(newValue,)=>{
    //   page1.value=newValue+1;
    // });
    watchEffect(() => {
      page1.value = props.page + 1;
    });
    return {
      page1,
    };
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getSampleCount();
    getPageEvents(routeTo, next);
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageEvents(routeTo, next);
  },
  computed: {
    hasNextPage() {
      return this.sample.sampleTotal > this.page * this.sample.perPage;
    },
    ...mapState(["sample"]),
  },
  methods: {
    jumpPage() {
      console.log(this.page1);
      this.$router.push({ name: "samplelist", params: { page: this.page1 } });
      // let routeData = this.$router.resolve({ name: "samplelist", params: { page: this.page1 }});
      // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
}
.link {
  margin-left: 1rem;
}
</style>
