<template>
  <!-- <router-link
    class="event-link"
    :to="{ name: 'EventDetails', params: { id: event.id } }"
  ></router-link> -->
  <div class="card">
    <div class="card-body">
      <span
        >任务单号:{{ taskno}};样品编号:{{sampleno}};样品名称{{
          sample.samplenickname
        }};规格型号:{{sample.modelnumber}};</span
      >
      <span>备注:{{sample.remark}};工程名称:{{ sample.trust.projectname }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sample: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sampleno() {
      return `JHYP-${this.sample.year}-${
        this.sample.samplecode
      }-${this.sample.samplenumber.toString().padStart(4, "0")}`;
    },
    taskno() {
      const temp = `JHRW-${this.sample.trust.year}-${this.sample.trust.tasknumber.toString().padStart(4, "0")}`;
      return temp;
    },
  },
};
</script>

<style scoped></style>
